exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-briteworx-index-tsx": () => import("./../../../src/pages/briteworx/index.tsx" /* webpackChunkName: "component---src-pages-briteworx-index-tsx" */),
  "component---src-pages-craftkitchens-index-tsx": () => import("./../../../src/pages/craftkitchens/index.tsx" /* webpackChunkName: "component---src-pages-craftkitchens-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lubricants-index-tsx": () => import("./../../../src/pages/lubricants/index.tsx" /* webpackChunkName: "component---src-pages-lubricants-index-tsx" */),
  "component---src-pages-transport-index-tsx": () => import("./../../../src/pages/transport/index.tsx" /* webpackChunkName: "component---src-pages-transport-index-tsx" */),
  "component---src-templates-job-description-tsx": () => import("./../../../src/templates/jobDescription.tsx" /* webpackChunkName: "component---src-templates-job-description-tsx" */)
}

